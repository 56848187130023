import { template as template_cc3a7a110a8f41b1a256f9682388a9ab } from "@ember/template-compiler";
import LightDarkImg from "discourse/components/light-dark-img";
const CategoryLogo = template_cc3a7a110a8f41b1a256f9682388a9ab(`
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryLogo;
