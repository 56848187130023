import { template as template_4743669c447641adb3239d825898a363 } from "@ember/template-compiler";
const FKText = template_4743669c447641adb3239d825898a363(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
