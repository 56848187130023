import { template as template_ed43c663450747a8879ec06f337cf425 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ed43c663450747a8879ec06f337cf425(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
