import { template as template_b47e3359ab374c2faad5f642f0d769c4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b47e3359ab374c2faad5f642f0d769c4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
